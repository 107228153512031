import React from 'react'
import styled from '@emotion/styled'
import Img from 'gatsby-image'
import { graphql, StaticQuery } from 'gatsby'

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: rgba(35,37,40,0.1);
  height: 100%;
`
const TitleHeader = styled.h1`
	word-break: keep-all;
	font-family: "Apple SD Gothic Neo Bold", "Apple SD Gothic Neo";
	align-items: center;
  font-size: 2.75em;
	font-weight: 700;
  text-align: center;
	line-height: 2.5rem;
	color: ${props => props.theme.colors.text};
	@media screen and (max-width: ${props => props.theme.responsive.small}) {
		font-size: 2em;
		line-height: 2.25rem;
	}
`
// Banner Section
const InfoBannerSection = styled.section`
  display: flex;
	flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5rem 0;
  background: ${`linear-gradient(315deg, rgba(188,224,244,0.55) 0%, #ECFFE9 100%)`};
	@media screen and (max-width: ${props => props.theme.responsive.small}) {
		padding: 3.5rem 0;
	}
`
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`
const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 24rem;
  background: rgba(35,37,40,0.1);
`
const DetailsDesc = styled.div`
  word-break: keep-all;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: fit-content;
  font-family: "Apple SD Gothic Neo";
  font-size: 1.5em;
  line-height: 1.4rem;
  margin: 2.5rem auto;
  color: #5B6369;
`
const StyledImg = styled(Img)`
  display: flex;
  width: 100%;
  height: 100%;
`
const PlaceholderImage = ({ filename, width }) => {
	return (
		<StaticQuery
			query={graphql`
				query {
					under_construction: file(relativePath: { eq: "under_construction.png" }) {
						childImageSharp {
							fixed(width: 350, height: 350, quality: 85) {
								...GatsbyImageSharpFixed
							}
						}
					}
        }
			`}
			render={data => (
				<StyledImg
					className="PlaceholderImage"
					fixed={data[`${filename}`].childImageSharp.fixed}
					width={width}
          layout="constrained"
				/>
			)}
		/>
	)
}
const UnderConstruction = ({title}) => {
  return (
    <PageContainer>
      <InfoBannerSection padding={0}>
        <TitleContainer>
          <TitleHeader>
            {title}
          </TitleHeader>
        </TitleContainer>
			</InfoBannerSection>
      <MessageContainer>
          <PlaceholderImage filename={"under_construction"} width={50} />
          <DetailsDesc>{`현재 준비 중입니다.`}</DetailsDesc>
      </MessageContainer>
    </PageContainer>
  )
}

export default UnderConstruction
